import React from 'react';
import PropTypes from 'prop-types';

import PaginationDot from '../../atoms/pagination-dot/pagination-dot';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = (event, index) => {
    this.props.onChangeIndex(index);
  };

  render() {
    const { index, dots } = this.props;

    const children = [];

    for (let i = 0; i < dots; i += 1) {
      children.push(
        <PaginationDot
          key={i}
          index={i}
          active={i === index}
          onClick={this.handleClick}
        />
      );
    }

    return (
      <div className="f-pagination">
        <div className="f-pagination-align">{children}</div>
      </div>
    );
  }
}

Pagination.propTypes = {
  dots: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func.isRequired,
};

export default Pagination;
