import PropTypes from 'prop-types';
import React from 'react';

class PaginationDot extends React.Component {

  constructor(props) {
    super(props);
  }
  
  handleClick = event => {
    this.props.onClick(event, this.props.index);
  };

  render() {

    const { index, active } = this.props;

    return (
      <>
        <div
          className={`f-pagination-dot-number ${active ? 'active' : ''}`}
          onClick={this.handleClick}
        >
          {(index + 1).toString().padStart(2, '0')}
          {active ? <div className="f-line"></div> : null}
        </div>
        <button
          type="button"
          className="f-pagination-button"
          onClick={this.handleClick}
          aria-label="Boton"
        >
          <div className={`f-pagination-dot ${active ? 'active' : ''}`} />
        </button>
      </>
    );
  }
}

PaginationDot.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PaginationDot;
